import { useSyncExternalStore } from "react";
import { useInterval } from "@business-finland/wif-ui-lib";

export default function useHashScroll(disabled?: boolean) {
  const hash = useSyncExternalStore(
    subscribeToHashChange,
    getWindowHashSnapshot,
    () => undefined
  );

  useInterval(
    () => {
      if (hash) {
        const element = document.getElementById(hash);

        if (element) {
          window.scrollTo(0, element.offsetTop);

          return true;
        }
      }
    },
    100,
    {
      maxDuration: 2000,
      disabled: disabled || !hash,
      executeImmediately: true,
    }
  );
}

function subscribeToHashChange(listener: () => void) {
  listener();
  window.addEventListener("hashchange", listener);
  return () => window.removeEventListener("hashchange", listener);
}

function getWindowHashSnapshot() {
  return window.location.hash.slice(1);
}
