import { useRouter } from "next/router";
import { useEffect } from "react";

import { formatPageLink } from "../util/magnolia";

export default function usePageRedirect(
  redirectPage: string | null,
  redirectAnchorTag: string | null
) {
  const { replace, isPreview } = useRouter();

  useEffect(() => {
    // Handle GNF: Redirect template
    if (redirectPage && !global.mgnlInPageEditor) {
      const redirectPath = formatPageLink(
        redirectPage,
        false,
        redirectAnchorTag
      );

      replace(redirectPath);
    }
  }, [replace, redirectPage, redirectAnchorTag, isPreview]);
}
